export default {
  name: 'TimeoutingComponent',
  props: {
    delay: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      visible: true,
      timeout: null,
    };
  },
  mounted() {
    // If delay is equal to 0 then component is sleepless
    if (this.delay === 0) {
      return;
    }

    this.timeout = setTimeout(() => {
      this.visible = false;
    }, this.delay);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
